<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />
          <b-img
            :src="appLogoImage"
            alt="logo"
          />
        </b-link>

        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            autocomplete="off"
            @submit.prevent="forgotPassword"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="Email Address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Send reset link
            </b-button>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          <b-link
            v-if="this.$route.name == constants.USER_FORGOT_PASSWORD"
            to="/login"
          >
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
          <b-link
            v-else-if="this.$route.name == constants.SUPER_ADMIN_FORGOT_PASSWORD"
            to="/admin"
          >
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '../../store'
import constants from '@/constants'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
      constants,
    }
  },
  mounted() {
  },
  methods: {
    forgotPassword() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line eqeqeq
          if (this.$route.name == constants.SUPER_ADMIN_FORGOT_PASSWORD) {
            store.dispatch('PasswordStore/adminForgot', { email: this.userEmail }).then(response => {
            // this.$router.push({ name: 'auth-reset-password' })
              // eslint-disable-next-line eqeqeq
              if (response.data.code == constants.SUCCESS) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Forgot Mail Sent!',
                    icon: 'Success',
                    variant: 'success',
                    text: 'Please check the Mail and Reset Password',
                  },
                })
                this.$router.push('/login')
                // eslint-disable-next-line eqeqeq
              } else if (response.data.code == constants.ERROR) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Mail not Sent!',
                    icon: 'Danger',
                    variant: 'danger',
                    text: `${response.data.msg}`,
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Something Went Wrong!',
                    icon: 'Danger',
                    variant: 'danger',
                    text: '',
                  },
                })
              }
            }).catch(e => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${e.msg}`,
                  icon: 'Danger',
                  variant: 'danger',
                  text: '',
                },
              })
            })
          // eslint-disable-next-line eqeqeq
          } else if (this.$route.name == constants.USER_FORGOT_PASSWORD) {
            store.dispatch('PasswordStore/forgot', { email: this.userEmail }).then(response => {
              // eslint-disable-next-line eqeqeq
              if (response.data.code == constants.SUCCESS) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Forgot Mail Sent!',
                    icon: 'Success',
                    variant: 'success',
                    text: 'Please check the Mail and Reset Password',
                  },
                })
                this.$router.push('/login')
                // eslint-disable-next-line eqeqeq
              } else if (response.data.code == constants.ERROR) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Mail not Sent!',
                    icon: 'Danger',
                    variant: 'danger',
                    text: `${response.data.msg}`,
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Something Went Wrong!',
                    icon: 'Danger',
                    variant: 'danger',
                    text: '',
                  },
                })
              }
            }).catch(e => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${e.msg}`,
                  icon: 'Danger',
                  variant: 'danger',
                  text: '',
                },
              })
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
